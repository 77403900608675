#intro-slider {
  background-image: url("../../assets/landingthree.jpeg");

  background-repeat: no-repeat;
  position: absolute;

  width: 100%;
  height: 80%;

  background-size: 100% 95%;

  background-repeat: no-repeat;
}
.full-width-slider {
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  position: relative;
}
