/* .animation {
    animation-name: zoom;
    animation-duration: 10s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 2;
  }
  
  @keyframes zoom {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  } */
  .animation {
    animation-name: zoom;
    animation-duration: 10s; /* Adjust duration as needed */
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  
  @keyframes zoom {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .new {
  
    background-image: linear-gradient(
        to left,
        rgba(2, 1, 9, 0.01),
        rgba(3, 3, 3, 0.9)
      ),url("../../assets/landingtwo.jpeg");
    /* background-repeat: no-repeat;
           background-size: cover; */
    height: auto;
    width: 100%;
    /* background-size: contain; */
    background-size: cover;
  
    background-size: 100% 85%;
  
    background-repeat: no-repeat;
  }
  